import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { navDelay, loaderDelay } from '@utils';
import { usePrefersReducedMotion } from '@hooks';
// import { email } from '@config';

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  padding: 0;

  @media (max-width: 480px) and (min-height: 700px) {
    padding-bottom: 10vh;
  }

  h1 {
    margin: 0 0 30px 4px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 600;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h2 {
    color: var(--darkest-brown);
  }

  h3 {
    margin-top: 10px;
    color: var(--dark-brown);
    line-height: 0.9;
    font-weight: bold;
  }

  p {
    color: var(--darker-brown);
    margin: 20px 0 0;
    max-width: 600px;
  }

  a {
    color: var(--green);
    font-weight: bold;
  }

  b {
    color: var(--green);
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const one = <h1>Hi! my name is</h1>;
  const two = <h2 className="big-heading">Jeffrey Qiu,</h2>;
  const three = <h3 className="big-heading">who turns coffee into code.</h3>;
  const four = (
    <>
      <p>
        I graduated from{' '}
        <a href="https://www.washington.edu/" target="_blank" rel="noreferrer">
          University of Washington
        </a>{' '}
        in 2023, with a dual degree <br />
        in{' '}
        <a href="https://ischool.uw.edu/" target="_blank" rel="noreferrer">
          B.S. Software Engineering
        </a>{' '}
        and{' '}
        <a href="https://stat.uw.edu/" target="_blank" rel="noreferrer">
          B.S. Statistics, Data Science
        </a>
        .
      </p>
    </>
  );

  const five = (
    <>
      <p>
        I am now a software engineer, with expertise in{' '}
        <b>
          full-stack development, cloud computing, machine learning, scalable system & database
          design{' '}
        </b>
        .
      </p>
    </>
  );

  const items = [one, two, three, four, five];

  return (
    <StyledHeroSection>
      {prefersReducedMotion ? (
        <>
          {items.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </>
      ) : (
        <TransitionGroup component={null}>
          {isMounted &&
            items.map((item, i) => (
              <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
                <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
              </CSSTransition>
            ))}
        </TransitionGroup>
      )}
    </StyledHeroSection>
  );
};

export default Hero;
