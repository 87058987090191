import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 1000px;
  font-size: var(--fz-xl);

  p {
    color: var(--darker-brown);
  }

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 900px) {
      display: block;
    }
  }

  .skills-heading {
    display: flex;
    align-items: center;
    position: relative;
    margin: 40px 0 40px;
    width: 100%;
    font-size: var(--fz-xxl);
    white-space: nowrap;

    &:before {
      content: '';
      display: block;
      position: relative;
      top: -3px;
      width: 200px;
      height: 1px;
      margin-right: 20px;
      background-color: var(--dark-brown);

      @media (max-width: 1080px) {
        width: 100px;
      }
      @media (max-width: 900px) {
        width: 100%;
      }
      @media (max-width: 600px) {
        margin-right: 10px;
      }
    }

    &:after {
      content: '';
      display: block;
      position: relative;
      top: -3px;
      width: 200px;
      height: 1px;
      margin-left: 20px;
      background-color: var(--dark-brown);

      @media (max-width: 1080px) {
        width: 100px;
      }
      @media (max-width: 900px) {
        width: 100%;
      }
      @media (max-width: 600px) {
        margin-left: 10px;
      }
    }
  }

  .skills {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 20px;

    h4 {
      font-size: var(--fz-lg);
      color: var(--darkest-brown);
    }

    @media (max-width: 900px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;

const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 900px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--light-brown);

    &:hover,
    &:focus {
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }

      .primary-img {
        opacity: 0;
      }

      .hover-img {
        opacity: 1;
      }
    }

    .primary-img,
    .hover-img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
      backface-visibility: hidden;
      background-color: transparent;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
    }

    .hover-img {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transform: rotateY(180deg);
    }

    &:hover {
      .primary-img {
        transform: rotateY(180deg);
        opacity: 0;
      }

      .hover-img {
        transform: rotateY(0);
        opacity: 1;
      }
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  // Certificates array
  const certificates = ['AWS Certified Developer Associate', 'AWS Certified Cloud Practitioner'];

  // Languages array
  const languages = [
    'Java',
    'Python',
    'JavaScript',
    'TypeScript',
    'Go',
    'PHP',
    'C/C++',
    'C#',
    'SQL',
    'PostgreSQL',
    'GraphQL',
    'HTML/CSS',
  ];

  // Frameworks & Libraries array
  const frameworksAndLibraries = [
    'Spring Boot',
    'Django',
    'Flask',
    'FastAPI',
    'Node.js',
    'Next.js',
    'Express',
    'React',
    'Vue.js',
    'Angular',
    'D3.js',
    'Bootstrap',
    'Redux',
  ];

  // Cloud Services array
  const cloudServices = ['AWS', 'GCP', 'Azure'];

  // Version Control array
  const versionControl = ['Git', 'Sourcetree', 'Bitbucket'];

  // CI/CD Pipeline array
  const ciCdPipeline = ['Jenkins', 'CircleCI', 'GitLab CI'];

  // Databases & Caching array
  const databasesAndCaching = ['Redis', 'MongoDB', 'SQLAlchemy'];

  // Other Tools & Technologies array
  const otherTools = [
    'Linux',
    'Bash/Shell',
    'DevOps',
    'Docker',
    'Kubernetes',
    'Kafka',
    'Salesforce',
    'Nginx',
    'Jira',
    'Postman',
    'RESTful APIs',
    'SOAP APIs',
    'Ajax',
    'WebSocket',
  ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <div>
              <p>
                Hi! I'm Jeffrey, a software engineer at AWS working on large-scale cloud
                infrastructure. With roots spread across four countries, I bring a global
                perspective to problem-solving and team collaboration.
              </p>

              <p>
                When I'm not turning coffee into code, you'll find me lifting weights, exploring
                Seattle on two wheels, or losing my reading spot to my two cats who believe
                keyboards make the best napping spots!
              </p>

              <p>[ hover to meet my feline code reviewers.. ]</p>
            </div>

            <br />

            {/* <h2 className="skills-heading"> [ My Expertise ]</h2>

            <div className="skills">
              <div>
                <h4>Certificates I have earned:</h4>
                <ul className="skills-list">
                  <li>AWS Certified Developer Associate</li>
                  <br />
                  <li>AWS Certified Cloud Practitioner</li>
                </ul>
              </div>

              <div>
                <h4>Programming Languages:</h4>
                <ul className="skills-list">
                  {languages && languages.map((skill, i) => <li key={i}>{skill}</li>)}
                </ul>
              </div>

              <div>
                <h4>Frameworks & libraries I use:</h4>
                <ul className="skills-list">
                  {frameworksAndLibraries &&
                    frameworksAndLibraries.map((skill, i) => <li key={i}>{skill}</li>)}
                </ul>
              </div>

              <div>
                <h4>Tools & technologies I worked with:</h4>
                <ul className="skills-list">
                  {otherTools && otherTools.map((skill, i) => <li key={i}>{skill}</li>)}
                </ul>
              </div>

              <div>
                <h4>Cloud Services:</h4>
                <ul className="skills-list">
                  {cloudServices && cloudServices.map((skill, i) => <li key={i}>{skill}</li>)}
                </ul>
              </div>

              <div>
                <h4>Version Control Systems:</h4>
                <ul className="skills-list">
                  {versionControl && versionControl.map((skill, i) => <li key={i}>{skill}</li>)}
                </ul>
              </div>

              <div>
                <h4>CI/CD Process & Pipeline Tools:</h4>
                <ul className="skills-list">
                  {ciCdPipeline && ciCdPipeline.map((skill, i) => <li key={i}>{skill}</li>)}
                </ul>
              </div>

              <div>
                <h4>Databases & Caching Technologies:</h4>
                <ul className="skills-list">
                  {databasesAndCaching &&
                    databasesAndCaching.map((skill, i) => <li key={i}>{skill}</li>)}
                </ul>
              </div>
            </div> */}
          </div>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="primary-img"
              src="../../images/me.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
            <StaticImage
              className="hover-img"
              src="../../images/cats.jpeg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="My cats"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
